import * as React from 'react';

import {Modal} from '../../content/questions/components/modal';

export interface EnergeticSheetExplanationModalProps {
    onClose: () => void;
}

export function EnergeticSheetExplanationModal({onClose}: EnergeticSheetExplanationModalProps) {
    return (
        <Modal visible forced>
            <div className="modal-header">
                <h2>Energetisch rapport</h2>
            </div>
            <div className="modal-body">
                <div className="d-flex justify-content-center">
                    <span className="ion-md-calculator" style={{fontSize: 100}}></span>
                </div>
                <strong>De duurzaamheidstool</strong>
                <p>
                    Deze duurzaamheids-tool is bedoeld om jou als taxateur te helpen een beter onderbouwd advies te
                    geven welke aanpassingen aan de woning tot een beter energielabel leiden. Naast het automatisch
                    invullen van de vraag &quot;Is het mogelijk het energielabel te verbeteren?&quot; probeert deze tool
                    je te helpen met de onderbouwing. Op basis van je voorkeuren kun je een duurzaamheidsadvies ophalen.
                    Als extraatje kun je ook een PDF met deze adviezen genereren, welke ook voor de opdrachtgever terug
                    te vinden zal zijn in diens taXapi-portaal.
                </p>
                <strong>Bron & berekening</strong>
                <p>
                    De gebruikte kosten in het model zijn opgesteld en afkomstig van Arcadis in opdracht van het RVO en
                    wordt 2x per jaar bijgesteld. Het model hanteert de nieuwe NTA 8800 energielabel methodiek om de
                    besparingen en/of maatregelen samen te stellen. Het kan zomaar zijn dat het door ons
                    &quot;berekende&quot; energielabel 1 label afwijkt van het feitelijke label. We gebruiken de actuele
                    situatie zoals door taxateur is ingegeven maar deze kan iets afwijken van de methodiek zoals een
                    energiedeskundige het label heeft bepaald. Voor de berekening zelf zijn de belangrijkste gegevens:
                    woningtype, bouwjaar, oppervlakte en de huidige aanwezige maatregelen.
                </p>
            </div>
            <div className="modal-footer">
                <button className="btn btn-primary btn-full ion-md-close" type="button" onClick={() => onClose()}>
                    Sluiten
                </button>
            </div>
        </Modal>
    );
}
