import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../../../../models/appraisal';
import {Presenter} from '../../../../../support/presenter/presenter';
import {SurfaceAreaProvider} from '../../../../business/support/surface_area_provider';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {BuildYearProvider} from '../../../../business/build_year_provider';
import {QuestionSet} from '../../../../models/question_set';
import {TechnicalReference} from '../../../../enum/technical_reference';
import {getNewestAnswer} from '../../../../../support/get_newest_answer';
import {AnswerController} from '../../../../business/answering/answer_controller';
import {QuestionEffectInteractor} from '../../../../business/conditions/question_effects_interactor';
import {AnswerContentBuilder} from '../../../../business/adapted_values/internal/answer_content_builder';
import {sortByUpdatedAt} from '../../../../../support/sort_answer';
import {FloorQuestionType, IteratorQuestionType, NormalQuestionType} from '../../../../enum/question_type';
import {EnergeticSheetSearchCriteria, EnergeticSheetSettings} from './energetic_sheet_settings';
import {SustainabilityApi} from '../../../../network/sustainability_api';
import {
    Sustainability,
    SustainabilityGlass,
    SustainabilityInstallation,
    SustainabilityInsulation,
    SustainabilityMeasure,
    SustainabilityVentilation,
} from '../../../../models/sustainability';
import {FlashMessageBroadcaster, Type} from '../../../../business/flash_message_broadcaster';
import {AnswerPathStubber} from '../../../../business/answering/answer_path_stubber';
import {FileReference} from '../../../../models/file_reference';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {FloorInteractor, FloorTypeAnswerOptionContents} from '../../../../business/floor_interactor';
import {GlobalProvider} from '../../../../../business/global_provider';
import {debounceTime} from 'rxjs';
import {differenceInDays} from 'date-fns';
import {isEmpty} from '../../../../../support/util';
import {AnswerTouchState} from '../../../../enum/answer_touch_state';
import {EnergyLabelProvider} from '../../../../business/energy_label_provider';
import {UserInteractor} from '../../../../business/user_interactor';

export interface EnergeticSheetInput {
    key: string;
    name: string;
    value: unknown;
    displayValue?: string;
    storedInput?: string;
    customOptions?: {[label: string]: unknown};
}

export interface SuggestedMeasure<T = unknown> extends SustainabilityMeasure<T> {
    key: keyof Sustainability;
    displayName: string;
    footNote?: string;
}

export class EnergeticSheetPresenter implements Presenter {
    private subscriptions = new CompositeSubscription();
    @observable private surfaceArea: number | null = null;
    @observable private objectLabel: string | null = null;

    @observable public inputs: EnergeticSheetInput[] = [];
    @observable public settings: EnergeticSheetSettings = {
        targetLabel: null,
        maxInvestment: null,
        searchCriteria: EnergeticSheetSearchCriteria.LOWEST_INVESTMENT,
        electricityUsage: null,
        gasUsage: null,
        inhabitants: null,
    };
    @observable public loading = false;
    @observable public notFound = false;
    @observable public isDownloadingReport = false;
    @observable public report: FileReference | null = null;
    @observable public sustainability: Sustainability | null = null;
    @observable public storedInputs: {[key: string]: unknown} = {};

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private answerUuid: string,
        private pagePartsSet: PagePartsSet | null,
        globalProvider: GlobalProvider,
        private surfaceAreaProvider: SurfaceAreaProvider,
        private buildYearProvider: BuildYearProvider,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private answerContentBuilder: AnswerContentBuilder,
        private sustainabilityApi: SustainabilityApi,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private answerPathStubber: AnswerPathStubber,
        private floorInteractor: FloorInteractor,
        private energyLabelProvider: EnergyLabelProvider,
        private userInteractor: UserInteractor
    ) {
        makeObservable(this);
    }

    @computed
    public get measures() {
        if (!this.sustainability) {
            return [];
        }

        const hasImprovements = (measure: SuggestedMeasure) =>
            measure.current.value !== null &&
            measure.current.description !== null &&
            measure.future.value !== null &&
            measure.future.description !== null &&
            measure.current.value !== measure.future.value;

        const measureWithDisplayName = (
            key: keyof Sustainability,
            displayName: string,
            adaptDescription?: (desc: string) => string,
            footNote?: string
        ) => {
            const measure = this.sustainability?.[key] as SustainabilityMeasure<unknown>;

            return {
                ...measure,
                key,
                displayName,
                footNote,
                current: {
                    ...measure.current,
                    description: adaptDescription
                        ? adaptDescription(measure.current.description ?? '')
                        : measure.current.description,
                },
                future: {
                    ...measure.future,
                    description: adaptDescription
                        ? adaptDescription(measure.future.description ?? '')
                        : measure.future.description,
                },
            };
        };

        const insulationFootNote =
            'De beginsituatie m.b.t. isolatie kan aangepast worden onder "Basisgegevens voor advies" mocht dit afwijken van de daadwerkelijke situatie.';

        const measures: SuggestedMeasure[] = [
            measureWithDisplayName('wallInsulation', 'Gevelisolatie', undefined, insulationFootNote),
            measureWithDisplayName('roofInsulation', 'Dakisolatie', undefined, insulationFootNote),
            measureWithDisplayName('floorInsulation', 'Vloerisolatie', undefined, insulationFootNote),
            measureWithDisplayName('livingRoomWindows', 'Glas woonkamer'),
            measureWithDisplayName('bedroomWindows', 'Glas slaapkamer'),
            measureWithDisplayName('ventilation', 'Ventilatie'),
            measureWithDisplayName('installation', 'Verwarming'),
            measureWithDisplayName('solarPanels', 'Zonnepanelen', (desc) => `${desc} m²`),
            measureWithDisplayName('shower', 'Douche-wtw'),
        ];

        return measures.filter((measure) => hasImprovements(measure));
    }

    @computed
    public get labelDiffers() {
        if (!this.sustainability || !this.objectLabel) {
            return true; // We show warning if we don't have the data
        }

        return this.sustainability.label.current.value !== this.objectLabel;
    }

    public mount() {
        this.subscriptions.add(
            this.surfaceAreaProvider.surfaceArea().subscribe((surfaceArea) => {
                this.surfaceArea = surfaceArea;
            })
        );

        let isFirstRequest = true;

        this.subscriptions.add(
            this.answerController
                .answersStream()
                .pipe(debounceTime(500))
                .subscribe(() => {
                    // Recalculate whenever answers change

                    let inputs: EnergeticSheetInput[] = [];

                    const buildYear = this.buildYearProvider.get();
                    if (buildYear !== null) {
                        inputs.push({
                            key: 'build_year',
                            name: 'Bouwjaar',
                            value: buildYear,
                        });
                    }

                    const objectType = this.appraisal.objectType;
                    if (objectType !== null) {
                        inputs.push({
                            key: 'house_type',
                            name: 'Objecttype',
                            value: objectType,
                        });
                    }

                    if (this.surfaceArea !== null) {
                        inputs.push({
                            key: 'inner_surface_area',
                            name: 'Gebruikersoppervlate',
                            value: this.surfaceArea,
                            displayValue: `${this.surfaceArea} m²`,
                        });
                    }

                    inputs.push(this.getSolarInput());

                    const heatRecoveringShower =
                        this.getAnswersForTechnicalReference(TechnicalReference.ENERGETIC_HEAT_RECOVERING_SHOWER)?.some(
                            (v) => v === '1'
                        ) ?? false;

                    inputs.push({
                        key: 'shower',
                        name: 'Douche-warmteterugwinningssysteem',
                        value: heatRecoveringShower,
                    });

                    const energeticAnswer = this.answerController.byUuid(this.answerUuid);
                    const storedInputs =
                        energeticAnswer && !isEmpty(energeticAnswer.contents)
                            ? JSON.parse(energeticAnswer.contents)
                            : {};

                    inputs.push(this.getInstallationInput());
                    inputs.push(this.getVentilationInput());
                    inputs = inputs.concat(this.getGlassInputs());
                    inputs = inputs.concat(this.getInsulationInputs(storedInputs));
                    inputs.push(this.getEnergyLabelInput());

                    runInAction(() => {
                        this.inputs = inputs;
                        this.storedInputs = storedInputs;
                    });

                    // If it doesn't cost the user anything, we auto-fetch
                    if (isFirstRequest && !this.userInteractor.isEmployee()) {
                        this.sendRequest();
                    }
                    isFirstRequest = false;
                })
        );

        this.subscriptions.add(
            this.energyLabelProvider.stream().subscribe((label) => runInAction(() => (this.objectLabel = label)))
        );

        const reportQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.ATTACHMENT_SUSTAINABILITY_REPORT
        );
        if (reportQuestion) {
            const reportAnswer = getNewestAnswer(this.answerController.answersForQuestionUuid(reportQuestion.uuid));
            if (reportAnswer) {
                runInAction(() => {
                    this.report = reportAnswer.file;
                });
            }
        }
    }

    public unmount() {
        this.subscriptions.clear();
    }

    @action
    public setSettings(settings: EnergeticSheetSettings) {
        this.settings = settings;
    }

    @action
    public setStoredInput(key: string, value: unknown) {
        this.storedInputs[key] = value;
        this.answerController.onContentsChange(
            this.answerUuid,
            JSON.stringify(this.storedInputs),
            AnswerTouchState.TOUCHED
        );
    }

    private getRequestOptions() {
        const options: {[key: string]: unknown} = {
            heat_pump_allowed: true,
            replace_boiler: true,
        };

        for (const input of this.inputs) {
            if (input.key === 'solar_surface_area') {
                options['watt_panels'] = 1;
                options['solar_panels'] = Math.round(input.value as number);
            } else if (input.key === 'solar_watt_peak') {
                options['watt_panels'] = 0;
                options['solar_panels'] = Math.round(input.value as number);
            } else {
                options[input.key] = input.value;
            }
        }

        if (this.settings.targetLabel !== null) {
            options['target_label'] = this.settings.targetLabel;
        }

        if (this.settings.maxInvestment !== null) {
            const value =
                typeof this.settings.maxInvestment === 'string'
                    ? parseInt(this.settings.maxInvestment.replace('.', ''), 10)
                    : this.settings.maxInvestment;
            if (!isNaN(value)) {
                options['max_investment'] = value;
            }
        }

        if (this.settings.searchCriteria !== null) {
            options['search_criteria'] = this.settings.searchCriteria;
        }

        if (
            this.settings.searchCriteria === EnergeticSheetSearchCriteria.LOWEST_INVESTMENT &&
            this.settings.maxInvestment !== null
        ) {
            options['search_criteria'] = EnergeticSheetSearchCriteria.LOWEST_BENG2; // When we enter a specific budget, try to reach the highest possible energy label to fully use the budget
        }

        if (this.settings.electricityUsage !== null) {
            const value =
                typeof this.settings.electricityUsage === 'string'
                    ? parseInt(this.settings.electricityUsage.replace('.', ''), 10)
                    : this.settings.electricityUsage;
            if (!isNaN(value)) {
                options['electricity_usage'] = value;
            }
        }

        if (this.settings.gasUsage !== null) {
            const value =
                typeof this.settings.gasUsage === 'string'
                    ? parseInt(this.settings.gasUsage.replace('.', ''), 10)
                    : this.settings.gasUsage;
            if (!isNaN(value)) {
                options['gas_usage'] = value;
            }
        }

        if (this.settings.inhabitants !== null) {
            const value =
                typeof this.settings.inhabitants === 'string'
                    ? parseInt(this.settings.inhabitants.replace('.', ''), 10)
                    : this.settings.inhabitants;
            if (!isNaN(value)) {
                options['inhabitants'] = value;
            }
        }

        return options;
    }

    public async sendRequest() {
        runInAction(() => {
            this.loading = true;
            this.notFound = false;
            this.sustainability = null;
        });

        try {
            const {sustainability} = await this.sustainabilityApi.getSustainabilityInfo(
                this.appraisal.id,
                this.getRequestOptions()
            );
            runInAction(() => {
                this.loading = false;
                this.notFound = sustainability === null;
                this.sustainability = sustainability;
            });
        } catch (e) {
            console.error(e);
            this.flashMessageBroadcaster.broadcast(
                'Er is een fout opgetreden bij het ophalen van de duurzaamheidsinformatie.',
                Type.Danger
            );
            runInAction(() => {
                this.notFound = false;
                this.loading = false;
            });
        }
    }

    public async downloadReport() {
        runInAction(() => {
            this.isDownloadingReport = true;
            this.report = null;
        });

        try {
            const {report} = await this.sustainabilityApi.getSustainabilityReport(
                this.appraisal.id,
                this.getRequestOptions()
            );

            runInAction(() => {
                this.report = report;
            });

            if (report === null) {
                this.flashMessageBroadcaster.broadcast(
                    'Er is een fout opgetreden bij het ophalen van de duurzaamheidsinformatie.',
                    Type.Danger
                );
                return;
            }

            const question = this.questionSet.findQuestionByTechnicalReference(
                TechnicalReference.ATTACHMENT_SUSTAINABILITY_REPORT
            );
            if (question) {
                const filled = this.answerPathStubber.stubPathForQuestionUuid(question.uuid, [], {
                    [question.uuid]: {contents: null, file: report},
                });

                if (filled.length > 0) {
                    this.flashMessageBroadcaster.broadcast(
                        'Het duurzaamheidsrapport is opgeslagen bij de bijlagen.',
                        Type.Success
                    );
                }
            }
        } catch (e) {
            console.error(e);
            this.flashMessageBroadcaster.broadcast(
                'Er is een fout opgetreden bij het ophalen van de duurzaamheidsinformatie.',
                Type.Danger
            );
        } finally {
            runInAction(() => {
                this.isDownloadingReport = false;
            });
        }
    }

    public fillMeasures() {
        const measures = this.measures;

        // Check roof types present
        const rootTypeQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.ENERGETIC_ROOF_TYPE
        );
        const roofTypeOptions = rootTypeQuestion
            ? this.questionSet.findChildQuestionsByParentUuid(rootTypeQuestion.uuid)
            : [];

        const roofTypes = roofTypeOptions
            .map((q) => [q, getNewestAnswer(this.answerController.answersForQuestionUuid(q.uuid))] as const)
            .filter(([, a]) => a !== null && (a.contents === '1' || a.contents?.toLowerCase() === 'ja'))
            .map(([q]) => q.reportValue?.toLowerCase() ?? null)
            .filter((v): v is string => v !== null);

        const angledRoofTypes = [
            'dwarskap',
            'gebogen dak',
            'golvend dak',
            'lessenaarsdak',
            'mansardedak',
            'samengesteld dak',
            'schilddak',
            'tentdak',
            'zadeldak',
        ];
        const flatRoofTypes = ['plat dak'];

        const hasAngledRoof = roofTypes.some((t) => angledRoofTypes.includes(t));
        const hasFlatRoof = roofTypes.some((t) => flatRoofTypes.includes(t));

        const installationMeasure = measures.find((m) => m.key === 'installation') as
            | SuggestedMeasure<SustainabilityInstallation | null>
            | undefined;
        const hasHeatPump =
            installationMeasure?.future.value &&
            [
                SustainabilityInstallation.HP_AIR,
                SustainabilityInstallation.HP_COLLECTIVE,
                SustainabilityInstallation.HP_HYBRID,
                SustainabilityInstallation.HP_SOIL,
            ].includes(installationMeasure?.future.value);
        const hasSolarBoiler =
            installationMeasure?.future.value === SustainabilityInstallation.HR_WITH_SOLAR ||
            installationMeasure?.future.value === SustainabilityInstallation.VR_WITH_SOLAR;

        const map: Partial<Record<TechnicalReference, boolean>> = {
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_POSSIBLE]: measures.length > 0,
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_WALL]: measures.some((m) => m.key === 'wallInsulation'),
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_FLAT_ROOF]:
                hasAngledRoof && measures.some((m) => m.key === 'roofInsulation'),
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_ANGLED_ROOF]:
                hasFlatRoof && measures.some((m) => m.key === 'roofInsulation'),
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_FLOOR]: measures.some((m) => m.key === 'floorInsulation'),
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_GLASS_LIVING_ROOM]: measures.some(
                (m) => m.key === 'livingRoomWindows'
            ),
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_GLASS_BEDROOM]: measures.some((m) => m.key === 'bedroomWindows'),
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_VENTILATION]: measures.some((m) => m.key === 'ventilation'),
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_SOLAR]: measures.some((m) => m.key === 'solarPanels'),
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_SHOWER]: measures.some((m) => m.key === 'shower'),
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_HEAT_PUMP]: hasHeatPump ? true : false,
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_SOLAR_BOILER]: hasSolarBoiler,
            [TechnicalReference.ENERGETIC_IMPROVEMENTS_OTHER_HEATING]:
                installationMeasure?.future.value && !hasHeatPump && !hasSolarBoiler ? true : false,
        };

        for (const [technicalReference, value] of Object.entries(map)) {
            const question = this.questionSet.findQuestionByTechnicalReference(
                technicalReference as TechnicalReference
            );
            if (!question) {
                continue;
            }

            this.answerPathStubber.stubPathForQuestionUuid(question.uuid, [], {
                [question.uuid]: value,
            });
        }

        this.flashMessageBroadcaster.broadcast(
            'De duurzaamheidsmaatregelen zijn ingevuld bij de verbetermogelijkheden.',
            Type.Success
        );
    }

    private getSolarInput(): EnergeticSheetInput {
        const solarInputMethod = this.getAnswerForTechnicalReference(TechnicalReference.ENERGETIC_SOLAR_INPUT_METHOD);
        const solarAmount = this.getAnswerForTechnicalReference(TechnicalReference.ENERGETIC_SOLAR_AMOUNT);

        if (solarInputMethod === '1' && solarAmount !== null && !isNaN(Number(solarAmount))) {
            const numberOfPanels = Number(solarAmount);
            // Altum uses 1.65 m2 per solar panel
            const surfaceArea = Math.round(numberOfPanels * 1.65);

            return {
                key: 'solar_surface_area',
                name: 'Zonnepanelen',
                value: surfaceArea,
                displayValue: `${surfaceArea} m²`,
            };
        }

        if (solarInputMethod === '0' && solarAmount !== null && !isNaN(Number(solarAmount))) {
            const solarWattPeak = Number(solarAmount);

            return {
                key: 'solar_watt_peak',
                name: 'Zonnepanelen',
                value: solarWattPeak,
                displayValue: `${solarWattPeak} Wp`,
            };
        }

        return {
            key: 'solar_surface_area',
            name: 'Zonnepanelen',
            value: 0,
            displayValue: `0 m²`,
        };
    }

    private getVentilationInput(): EnergeticSheetInput {
        const ventilationTypes = this.getAnswersForTechnicalReference(TechnicalReference.ENERGETIC_VENTILATION)
            ?.filter((v): v is string[] => Array.isArray(v))
            ?.flat();

        for (const ventilationType of ventilationTypes ?? []) {
            switch (ventilationType) {
                case 'mechanisch':
                case 'mechanische ventilatie': {
                    return {
                        key: 'ventilation',
                        name: 'Ventilatiesysteem',
                        value: SustainabilityVentilation.MECHANICAL,
                        displayValue: 'Mechanische ventilatie',
                    };
                }
                case 'gebalanceerd':
                case 'gebalanceerde ventilatie': {
                    return {
                        key: 'ventilation',
                        name: 'Ventilatiesysteem',
                        value: SustainabilityVentilation.BALANCED,
                        displayValue: 'Gebalanceerde ventilatie',
                    };
                }
                case 'decentraal mechanisch':
                case 'decentraal mechanische ventilatie': {
                    return {
                        key: 'ventilation',
                        name: 'Ventilatiesysteem',
                        value: SustainabilityVentilation.DECENTRALIZED_MECANICAL,
                        displayValue: 'Decentraal mechanische ventilatie',
                    };
                }
                case 'vraaggestuurd':
                case 'vraaggestuurde ventilatie': {
                    return {
                        key: 'ventilation',
                        name: 'Ventilatiesysteem',
                        value: SustainabilityVentilation.DEMAND_DRIVEN,
                        displayValue: 'Vraaggestuurde ventilatie',
                    };
                }
                case 'natuurlijke ventilatie':
                case 'natuurlijk': {
                    return {
                        key: 'ventilation',
                        name: 'Ventilatiesysteem',
                        value: SustainabilityVentilation.NATURAL,
                        displayValue: 'Natuurlijke ventilatie',
                    };
                }
            }
        }

        return {
            key: 'ventilation',
            name: 'Ventilatiesysteem',
            value: SustainabilityVentilation.NATURAL,
            displayValue: 'Natuurlijke ventilatie',
        };
    }

    private getInstallationInput(): EnergeticSheetInput {
        const installationTypes = this.getAnswersForTechnicalReference(TechnicalReference.ENERGETIC_INSTALLATION)
            ?.filter((v): v is string[] => Array.isArray(v) && v.length > 0)
            ?.flat();

        for (const installationType of installationTypes ?? []) {
            switch (installationType?.toLowerCase()) {
                case 'biomassaketel': {
                    return {
                        key: 'installation',
                        name: 'Verwarmingssysteem',
                        value: SustainabilityInstallation.BIOMASS,
                        displayValue: 'Biomassa',
                    };
                }
                case 'blokverwarming':
                case 'stadsverwarming': {
                    return {
                        key: 'installation',
                        name: 'Verwarmingssysteem',
                        value: SustainabilityInstallation.CITY,
                        displayValue: 'Stadsverwarming',
                    };
                }
                case 'centrale verwarming': {
                    return {
                        key: 'installation',
                        name: 'Verwarmingssysteem',
                        value: SustainabilityInstallation.CR,
                        displayValue: 'CV-ketel',
                    };
                }
                case 'hr-combiketel': {
                    return {
                        key: 'installation',
                        name: 'Verwarmingssysteem',
                        value: SustainabilityInstallation.HR,
                        displayValue: 'HR-ketel',
                    };
                }
                case 'vr-combiketel': {
                    return {
                        key: 'installation',
                        name: 'Verwarmingssysteem',
                        value: SustainabilityInstallation.VR,
                        displayValue: 'VR-ketel',
                    };
                }
                case 'bodem/water warmtepomp': {
                    return {
                        key: 'installation',
                        name: 'Verwarmingssysteem',
                        value: SustainabilityInstallation.HP_SOIL,
                        displayValue: 'Bodem/water warmtepomp',
                    };
                }
                case 'collectieve warmtepomp': {
                    return {
                        key: 'installation',
                        name: 'Verwarmingssysteem',
                        value: SustainabilityInstallation.HP_COLLECTIVE,
                        displayValue: 'Collectieve warmtepomp',
                    };
                }
                case 'hybride warmtepomp': {
                    return {
                        key: 'installation',
                        name: 'Verwarmingssysteem',
                        value: SustainabilityInstallation.HP_HYBRID,
                        displayValue: 'Hybride warmtepomp',
                    };
                }
                case 'lucht/water warmtepomp':
                case 'lucht/lucht warmtepomp': {
                    return {
                        key: 'installation',
                        name: 'Verwarmingssysteem',
                        value: SustainabilityInstallation.HP_AIR,
                        displayValue: 'Lucht/lucht warmtepomp',
                    };
                }
            }
        }

        return {
            key: 'installation',
            name: 'Verwarmingssysteem',
            value: null,
            displayValue: 'Onbekend/anders',
        };
    }

    private getGlassInputs(): EnergeticSheetInput[] {
        const inputs: EnergeticSheetInput[] = [];

        const mapGlassValue = (answerVal: string | string[] | null) => {
            for (const selectedOption of Array.isArray(answerVal) ? answerVal : [answerVal]) {
                switch (selectedOption?.toLowerCase()) {
                    case 'enkel glas': {
                        return {
                            value: SustainabilityGlass.SINGLE,
                            displayValue: 'Enkel glas',
                        };
                    }
                    case 'hr glas':
                    case 'hr+ glas':
                    case 'dubbel glas': {
                        return {
                            value: SustainabilityGlass.DOUBLE,
                            displayValue: 'Dubbel glas',
                        };
                    }
                    case 'drievoudig glas': {
                        return {
                            value: SustainabilityGlass.TRIPLE,
                            displayValue: 'Drievoudig glas',
                        };
                    }
                    case 'hr++ glas': {
                        return {
                            value: SustainabilityGlass.HR_PLUS_PLUS,
                            displayValue: 'HR++ glas',
                        };
                    }
                    default:
                        continue;
                }
            }

            return null;
        };

        if (this.pagePartsSet !== null) {
            const floors = this.floorInteractor.getFloors();

            const groundFloor = floors.find((f) => f.floorType === FloorTypeAnswerOptionContents.BEGANE_GROND);
            const otherFloors = floors.filter((f) => f.floorType !== FloorTypeAnswerOptionContents.BEGANE_GROND);

            if (groundFloor) {
                const glassFloorIteratorAnswer = this.getPagePartFloorAnswerForTechnicalReference(
                    TechnicalReference.ENERGETIC_GLASS,
                    groundFloor.iteration
                );
                if (glassFloorIteratorAnswer) {
                    const groundFloorGlass = this.getAnswerForTechnicalReference(
                        TechnicalReference.ENERGETIC_GLASS,
                        glassFloorIteratorAnswer.uuid
                    );
                    const value = mapGlassValue(groundFloorGlass);
                    if (value) {
                        inputs.push({
                            key: 'living_room_windows',
                            name: 'Glas begane grond',
                            ...value,
                        });
                    }
                }
            }

            for (const otherFloor of otherFloors) {
                const otherFloorIteratorAnswer = this.getPagePartFloorAnswerForTechnicalReference(
                    TechnicalReference.ENERGETIC_GLASS,
                    otherFloor.iteration
                );
                if (otherFloorIteratorAnswer) {
                    const otherFloorGlass = this.getAnswerForTechnicalReference(
                        TechnicalReference.ENERGETIC_GLASS,
                        otherFloorIteratorAnswer.uuid
                    );
                    const value = mapGlassValue(otherFloorGlass);
                    if (value) {
                        inputs.push({
                            key: 'bedroom_windows',
                            name: 'Glas overige verdiepingen',
                            ...value,
                        });
                        break;
                    }
                }
            }

            return inputs;
        }

        const floorData = this.getRawAnswersForTechnicalReference(TechnicalReference.FLOOR_TYPE);
        const bouwdelenData = this.getRawAnswersForTechnicalReference(TechnicalReference.FLOOR_BOUWDELEN_PARENT);
        if (!floorData || !bouwdelenData) return inputs;

        const floorTypesByBouwdelenUuid: {[uuid: string]: string | null} = {};
        for (const answer of floorData.answers ?? []) {
            const data = this.getRawAnswersForTechnicalReference(TechnicalReference.FLOOR_LAYOUT_PARENT, answer.uuid);
            if (data !== null && data?.answers.length > 0 && data.answers[0].iteration !== null) {
                const floorLayoutAnswer = data.answers[0];
                const bouwdeelAnswer = bouwdelenData.answers.find((a) => a.iteration === floorLayoutAnswer.iteration);
                if (bouwdeelAnswer !== undefined) {
                    floorTypesByBouwdelenUuid[bouwdeelAnswer.uuid] = this.answerContentBuilder.build(
                        answer,
                        floorData.question
                    );
                }
            }
        }

        const groundFloorUuid =
            Object.entries(floorTypesByBouwdelenUuid).find(([, floorType]) => floorType === 'Begane grond')?.[0] ??
            null;
        const otherFloorUuids = Object.entries(floorTypesByBouwdelenUuid)
            .filter(([, floorType]) => floorType === 'Verdieping')
            .map(([uuid]) => uuid);

        if (groundFloorUuid !== null) {
            const groundFloorGlass = this.getAnswerForTechnicalReference(
                TechnicalReference.ENERGETIC_GLASS,
                groundFloorUuid
            );
            const value = mapGlassValue(groundFloorGlass);
            if (value) {
                inputs.push({
                    key: 'living_room_windows',
                    name: 'Glas begane grond',
                    ...value,
                });
            }
        }

        for (const otherFloorUuid of otherFloorUuids) {
            const answerValue = this.getAnswerForTechnicalReference(TechnicalReference.ENERGETIC_GLASS, otherFloorUuid);
            const value = mapGlassValue(answerValue);
            if (value) {
                inputs.push({
                    key: 'bedroom_windows',
                    name: 'Glas overige verdiepingen',
                    ...value,
                });
            }
            break;
        }

        return inputs;
    }

    private getInsulationInputs(storedInputs: {[key: string]: unknown}): EnergeticSheetInput[] {
        const inputs: EnergeticSheetInput[] = [];

        const customOptions = {
            Geen: SustainabilityInsulation.NONE,
            'Matig/na-isolatie': SustainabilityInsulation.MEDIOCRE,
            Goed: SustainabilityInsulation.GOOD,
            'Zeer goed': SustainabilityInsulation.VERY_GOOD,
        };

        const buildYear = this.buildYearProvider.get();

        let floorInsulation = SustainabilityInsulation.GOOD;
        if (!isEmpty(storedInputs.floor_insulation)) {
            floorInsulation = storedInputs.floor_insulation as SustainabilityInsulation;
        } else if (buildYear !== null) {
            if (buildYear < 1983) {
                floorInsulation = SustainabilityInsulation.NONE;
            } else if (buildYear < 1991) {
                floorInsulation = SustainabilityInsulation.MEDIOCRE;
            } else if (buildYear < 2013) {
                floorInsulation = SustainabilityInsulation.GOOD;
            } else {
                floorInsulation = SustainabilityInsulation.VERY_GOOD;
            }
        }

        inputs.push({
            key: 'floor_insulation',
            name: 'Vloerisolatie',
            value: floorInsulation,
            storedInput: 'floor_insulation',
            displayValue: Object.entries(customOptions).find(([, value]) => value === floorInsulation)?.[0],
            customOptions,
        });

        let roofInsulation = SustainabilityInsulation.GOOD;
        if (!isEmpty(storedInputs.roof_insulation)) {
            roofInsulation = storedInputs.roof_insulation as SustainabilityInsulation;
        } else if (buildYear !== null) {
            if (buildYear < 1975) {
                roofInsulation = SustainabilityInsulation.NONE;
            } else if (buildYear < 1991) {
                roofInsulation = SustainabilityInsulation.MEDIOCRE;
            } else if (buildYear < 2013) {
                roofInsulation = SustainabilityInsulation.GOOD;
            } else {
                roofInsulation = SustainabilityInsulation.VERY_GOOD;
            }
        }

        inputs.push({
            key: 'roof_insulation',
            name: 'Dakisolatie',
            value: roofInsulation,
            storedInput: 'roof_insulation',
            displayValue: Object.entries(customOptions).find(([, value]) => value === roofInsulation)?.[0],
            customOptions,
        });

        let wallInsulation = SustainabilityInsulation.GOOD;
        if (!isEmpty(storedInputs.wall_insulation)) {
            wallInsulation = storedInputs.wall_insulation as SustainabilityInsulation;
        } else if (buildYear !== null) {
            if (buildYear < 1920) {
                wallInsulation = SustainabilityInsulation.NONE;
            } else if (buildYear < 1974) {
                wallInsulation = SustainabilityInsulation.MEDIOCRE;
            } else if (buildYear < 2013) {
                wallInsulation = SustainabilityInsulation.GOOD;
            } else {
                wallInsulation = SustainabilityInsulation.VERY_GOOD;
            }
        }

        inputs.push({
            key: 'wall_insulation',
            name: 'Gevelisolatie',
            value: wallInsulation,
            storedInput: 'wall_insulation',
            displayValue: Object.entries(customOptions).find(([, value]) => value === wallInsulation)?.[0],
            customOptions,
        });

        return inputs;
    }

    private getEnergyLabelInput(): EnergeticSheetInput {
        const energyLabelDate = this.getAnswerForTechnicalReference(TechnicalReference.ENERGETIC_ENERGYLABEL_DATE);

        if (energyLabelDate === null || Array.isArray(energyLabelDate)) {
            return {
                key: 'fetch_definitive_label',
                name: 'Recent energielabel',
                value: false,
            };
        }

        const date = new Date(energyLabelDate);
        const now = new Date();

        return {
            key: 'fetch_definitive_label',
            name: 'Recent energielabel',
            value: differenceInDays(now, date) < 365,
        };
    }

    private getAnswerForTechnicalReference(technicalReference: TechnicalReference, sameIterationUuid?: string) {
        return this.getAnswersForTechnicalReference(technicalReference, sameIterationUuid)?.pop() ?? null;
    }

    private getAnswersForTechnicalReference(technicalReference: TechnicalReference, sameIterationUuid?: string) {
        const result = this.getRawAnswersForTechnicalReference(technicalReference, sameIterationUuid);
        if (!result) {
            return [];
        }
        const {question, answers} = result;

        const isFloorQuestion =
            this.questionSet.findParentByPredicateRecursive(
                question,
                (q) => q.type === FloorQuestionType.FLOOR_GROUP_FLOOR
            ) !== null;

        return answers
            .filter((answer) => {
                if (!isFloorQuestion) {
                    return true;
                }

                // Ignore floor iterator automatically filled answers
                return this.answerController.getNearestIterationForAnswerUuid(answer.uuid) !== null;
            })
            .map((answer) => {
                if (
                    question.type === NormalQuestionType.MULTIPLE_BOOLEAN_GROUP ||
                    question.type === NormalQuestionType.CHECKLIST
                ) {
                    const childQuestions = this.questionSet.findChildQuestionsByParentUuid(answer.questionUuid);
                    return childQuestions
                        .map((childQuestion) => {
                            const childAnswer = getNewestAnswer(
                                this.answerController.filterDeleted(
                                    this.answerController.answersForQuestionUuid(childQuestion.uuid, answer.uuid)
                                )
                            );
                            if (childAnswer) {
                                return this.answerContentBuilder.build(childAnswer, childQuestion);
                            }
                            return null;
                        })
                        .filter((v): v is string => v !== null);
                } else {
                    return this.answerContentBuilder.build(answer, question);
                }
            })
            .filter((v): v is string[] | string => v !== null);
    }

    private getRawAnswersForTechnicalReference(technicalReference: TechnicalReference, sameIterationUuid?: string) {
        const question = this.questionSet.findQuestionByTechnicalReference(technicalReference);
        if (question !== null) {
            return {
                question,
                answers: this.answerController
                    .filterDeleted(
                        sameIterationUuid
                            ? this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                                  question.uuid,
                                  sameIterationUuid
                              ) ?? []
                            : this.answerController.answersForQuestionUuid(question.uuid)
                    )
                    .filter((answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.parentUuid))
                    .sort((a, b) => sortByUpdatedAt(a, b)),
            };
        }

        return null;
    }

    private getPagePartFloorAnswerForTechnicalReference(
        technicalReference: TechnicalReference,
        floorIteration: string
    ) {
        const question = this.questionSet.findQuestionByTechnicalReference(technicalReference);
        if (!question) return null;

        const iterator = this.questionSet.findParentByPredicateRecursive(
            question,
            (parent) => parent.type === IteratorQuestionType.PAGE_PART_ITERATOR
        );
        if (!iterator) return null;

        const answers = this.answerController
            .filterDeleted(
                this.answerController
                    .answersForQuestionUuid(iterator.uuid)
                    .filter((answer) => answer.iteration === floorIteration)
            )
            .filter((answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.parentUuid));

        return getNewestAnswer(answers);
    }
}
