export interface ApiAppraisalCosts {
    lines: ApiAppraisalCostLine[];
    optional_lines: ApiAppraisalCostLine[];
    automator_cost_packages: ApiAutomatorCostPackage[];
    automator_cost_details: ApiAutomatorCostDetails[];
}

export interface AppraisalCosts {
    lines: AppraisalCostLine[];
    optionalLines: AppraisalCostLine[];
    automatorCostPackages: AutomatorCostPackage[];
    automatorCostDetails: AutomatorCostDetails[];
}

export interface ApiAppraisalCostLine {
    cost_type: AppraisalCostType;
    display_name: string;
    short_name: string;
    cost_cents: number;
    original_cost_cents?: number;
    conditions?: ApiAppraisalCostCondition[];
    sublines?: ApiAppraisalCostLine[];
    covered_by?: AppraisalCostType[];
    note?: string;
    tooltip?: string;
}

export interface AppraisalCostLine {
    costType: AppraisalCostType;
    displayName: string;
    shortName: string;
    costCents: number;
    originalCostCents?: number;
    conditions?: AppraisalCostCondition[];
    sublines?: AppraisalCostLine[];
    coveredBy?: AppraisalCostType[];
    note?: string;
    tooltip?: string;
}

export interface ApiAutomatorCostPackage extends ApiAppraisalCostLine {
    required: boolean;
    covered_costs: {
        cost_type: AppraisalCostType;
        display_name: string;
        short_name: string;
    }[];
    dispatch_preferences: Record<AutomatorDispatchGroup, AutomatorDispatchTime>;
}

export interface AutomatorCostPackage extends AppraisalCostLine {
    required: boolean;
    coveredCosts: {
        costType: AppraisalCostType;
        displayName: string;
        shortName: string;
    }[];
    dispatchPreferences: Record<AutomatorDispatchGroup, AutomatorDispatchTime>;
}

export interface ApiAutomatorCostDetails {
    dispatch_group: AutomatorDispatchGroup;
    display_name: string;
    dispatch_times: AutomatorDispatchTime[];
    lines: ApiAppraisalCostLine[];
}

export interface AutomatorCostDetails {
    dispatchGroup: AutomatorDispatchGroup;
    displayName: string;
    dispatchTimes: AutomatorDispatchTime[];
    lines: AppraisalCostLine[];
}

// Not all possible cost types, only the ones encountered on the preferences page
export enum AppraisalCostType {
    TAXAPI_RATE = 'taxapi_rate',
    PRECHECK = 'precheck',
    SUBSCRIPTION_REFERENCES_ORTEC = 'subscription_references_ortec',
    SUBSCRIPTION_REFERENCES_BRAINBAY = 'subscription_references_brainbay',
    SUBSCRIPTION_REFERENCES_ALTUM = 'subscription_references_altum',
    SUBSCRIPTION_REFERENCES_DISCOUNT = 'subscription_references_discount',
    AUTOMATOR_PERCELEN = 'automator_percelen',
    AUTOMATOR_RUIMTELIJKE_PLANNEN = 'automator_ruimtelijke_plannen',
    AUTOMATOR_KADASTER_DEED_OF_DELVIERY = 'automator_kadaster_deed_of_delivery',
    AUTOMATOR_KADASTER_DOCUMENT = 'automator_kadaster_document',
    AUTOMATOR_PACKAGE_DEFAULT = 'automator_package_default',
    AUTOMATOR_PACKAGE_NEW_CONSTRUCTION = 'automator_package_new_construction',
    APPRAISAL_AVMS = 'appraisal_avms',
    PRE_REFERENCES_CHECK = 'pre_references_check',
    CORE_TASKS_REPORT = 'core_tasks_report',
    INVOICING = 'invoicing',
    CORE_TASKS_MODULE = 'core_tasks_module',
}

export interface ApiBaseAppraisalCostCondition {
    type: string;
}

export interface ApiAndAppraisalCostCondition extends ApiBaseAppraisalCostCondition {
    type: 'and';
    conditions: ApiAppraisalCostCondition[];
}

export interface ApiOrAppraisalCostCondition extends ApiBaseAppraisalCostCondition {
    type: 'or';
    conditions: ApiAppraisalCostCondition[];
}

export interface ApiNotAppraisalCostCondition extends ApiBaseAppraisalCostCondition {
    type: 'not';
    condition: ApiAppraisalCostCondition;
}

export interface ApiHasCostTypeAppraisalCostCondition extends ApiBaseAppraisalCostCondition {
    type: 'has_cost_type';
    cost_type: AppraisalCostType;
}

export type ApiAppraisalCostCondition =
    | ApiAndAppraisalCostCondition
    | ApiOrAppraisalCostCondition
    | ApiNotAppraisalCostCondition
    | ApiHasCostTypeAppraisalCostCondition;

export interface BaseAppraisalCostCondition {
    type: string;
}

export interface AndAppraisalCostCondition extends BaseAppraisalCostCondition {
    type: 'and';
    conditions: AppraisalCostCondition[];
}

export interface OrAppraisalCostCondition extends BaseAppraisalCostCondition {
    type: 'or';
    conditions: AppraisalCostCondition[];
}

export interface NotAppraisalCostCondition extends BaseAppraisalCostCondition {
    type: 'not';
    condition: AppraisalCostCondition;
}

export interface HasCostTypeAppraisalCostCondition extends BaseAppraisalCostCondition {
    type: 'has_cost_type';
    costType: AppraisalCostType;
}

export type AppraisalCostCondition =
    | AndAppraisalCostCondition
    | OrAppraisalCostCondition
    | NotAppraisalCostCondition
    | HasCostTypeAppraisalCostCondition;

export enum AutomatorDispatchTime {
    PRECHECK = 'precheck',
    CREATION = 'creation',
    DELAYED = 'delayed',
    MANUAL = 'manual',
    DISABLED = 'disabled',
}

export enum AutomatorDispatchGroup {
    INTERNAL = 'internal',
    TAXAPI_AI = 'taxapi-ai',
    BODEMLOKET = 'bodemloket',
    ENERGIELABEL = 'energielabel',
    WEATHER = 'weather',
    FUNDERINGSRISICO = 'funderingsrisico',
    PLANVIEWER = 'planviewer',
    RUIMTELIJKE_PLANNEN = 'ruimtelijke-plannen',
    KADASTER_DOCUMENT = 'kadaster-document',
    KADASTER_DEED_OF_DELIVERY = 'kadaster-deed-of-delivery',
}

export function apiAppraisalCostsToAppraisalCosts(apiAppraisalCosts: ApiAppraisalCosts): AppraisalCosts {
    return {
        lines: apiAppraisalCosts.lines.map(apiAppraisalCostLineToAppraisalCostLine),
        optionalLines: apiAppraisalCosts.optional_lines.map(apiAppraisalCostLineToAppraisalCostLine),
        automatorCostPackages: apiAppraisalCosts.automator_cost_packages.map(
            apiAutomatorCostPackageToAutomatorCostPackage
        ),
        automatorCostDetails: apiAppraisalCosts.automator_cost_details.map((apiAutomatorCostDetails) =>
            apiAutomatorCostDetailsToAutomatorCostDetails(apiAutomatorCostDetails)
        ),
    };
}

export function apiAppraisalCostLineToAppraisalCostLine(apiAppraisalCostLine: ApiAppraisalCostLine): AppraisalCostLine {
    return {
        costType: apiAppraisalCostLine.cost_type,
        displayName: apiAppraisalCostLine.display_name,
        shortName: apiAppraisalCostLine.short_name,
        costCents: apiAppraisalCostLine.cost_cents,
        originalCostCents: apiAppraisalCostLine.original_cost_cents,
        conditions: apiAppraisalCostLine.conditions?.map(apiAppraisalCostConditionToAppraisalCostCondition),
        sublines: apiAppraisalCostLine.sublines?.map(apiAppraisalCostLineToAppraisalCostLine),
        coveredBy: apiAppraisalCostLine.covered_by,
        note: apiAppraisalCostLine.note,
        tooltip: apiAppraisalCostLine.tooltip,
    };
}

export function apiAutomatorCostPackageToAutomatorCostPackage(
    apiAutomatorCostPackage: ApiAutomatorCostPackage
): AutomatorCostPackage {
    return {
        ...apiAppraisalCostLineToAppraisalCostLine(apiAutomatorCostPackage),
        required: apiAutomatorCostPackage.required,
        coveredCosts: apiAutomatorCostPackage.covered_costs.map((costType) => ({
            costType: costType.cost_type,
            displayName: costType.display_name,
            shortName: costType.short_name,
        })),
        dispatchPreferences: apiAutomatorCostPackage.dispatch_preferences,
    };
}

export function apiAutomatorCostDetailsToAutomatorCostDetails(
    apiAutomatorCostDetails: ApiAutomatorCostDetails
): AutomatorCostDetails {
    return {
        dispatchGroup: apiAutomatorCostDetails.dispatch_group,
        displayName: apiAutomatorCostDetails.display_name,
        dispatchTimes: apiAutomatorCostDetails.dispatch_times,
        lines: apiAutomatorCostDetails.lines.map(apiAppraisalCostLineToAppraisalCostLine),
    };
}

export function apiAppraisalCostConditionToAppraisalCostCondition(
    apiAppraisalCostCondition: ApiAppraisalCostCondition
): AppraisalCostCondition {
    switch (apiAppraisalCostCondition.type) {
        case 'and':
            return {
                type: 'and',
                conditions: apiAppraisalCostCondition.conditions.map(apiAppraisalCostConditionToAppraisalCostCondition),
            };
        case 'or':
            return {
                type: 'or',
                conditions: apiAppraisalCostCondition.conditions.map(apiAppraisalCostConditionToAppraisalCostCondition),
            };
        case 'not':
            return {
                type: 'not',
                condition: apiAppraisalCostConditionToAppraisalCostCondition(apiAppraisalCostCondition.condition),
            };
        case 'has_cost_type':
            return {
                type: 'has_cost_type',
                costType: apiAppraisalCostCondition.cost_type,
            };
        default:
            throw new Error(
                `Unknown appraisal cost condition type: ${
                    (apiAppraisalCostCondition as ApiBaseAppraisalCostCondition).type
                }`
            );
    }
}
