import {AjaxDriver} from '../../network/driver/ajax_driver';
import {getDefaultAjaxHeaders} from '../../support/api_headers';
import {FileReference} from '../models/file_reference';
import {Sustainability} from '../models/sustainability';
import {ApiFile, apiFileToFile} from './models/api_file';
import {ApiSustainability, apiSustainabilityToSustainability} from './models/api_sustainability';

export enum SustainabilitySubscriptionFrequency {
    SINGLE = 'single',
    MONTHLY = 'monthly',
}

export type SustainabilityResponse = {sustainability: Sustainability | null};
export type SustainabilityReportResponse = {report: FileReference | null};

export interface SustainabilityApi {
    getSustainabilityInfo(appraisalId: number, options: {[key: string]: unknown}): Promise<SustainabilityResponse>;

    getSustainabilityReport(
        appraisalId: number,
        options: {[key: string]: unknown}
    ): Promise<SustainabilityReportResponse>;
}

export class DefaultSustainabilityApi implements SustainabilityApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public getSustainabilityInfo(
        appraisalId: number,
        options: {[key: string]: unknown}
    ): Promise<SustainabilityResponse> {
        return new Promise<SustainabilityResponse>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/sustainability/request`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        options,
                    }),
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: {sustainability: ApiSustainability | null} = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            resolve({
                                sustainability: data.sustainability
                                    ? apiSustainabilityToSustainability(data.sustainability)
                                    : null,
                            });
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getSustainabilityReport(
        appraisalId: number,
        options: {[key: string]: unknown}
    ): Promise<SustainabilityReportResponse> {
        return new Promise<SustainabilityReportResponse>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/sustainability/report`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        options,
                    }),
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: {report: ApiFile | null} = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            resolve({
                                report: data.report ? apiFileToFile(data.report) : null,
                            });
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
