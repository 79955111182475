import {makeObservable, runInAction, observable, action} from 'mobx';
import {AppraisalProvider} from '../../../appraising/business/appraisal_provider';
import {Appraisal} from '../../../appraising/models/appraisal';
import {QuestionSet} from '../../../appraising/models/question_set';
import {QuestionSetApi} from '../../../appraising/network/question_set_api';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';

export class DecidePresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;
    @observable.ref public questionSet?: QuestionSet;
    @observable public reasoning = '';
    @observable public showReasoningRequiredError = false;

    private subscriptions = new CompositeSubscription();

    constructor(
        private questionSetId: number,
        private appraisalProvider: AppraisalProvider,
        private questionSetApi: QuestionSetApi
    ) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) =>
                runInAction(() => {
                    this.appraisal = appraisal;
                })
            )
        );

        this.questionSetApi.get(this.questionSetId).then((questionSet) => {
            runInAction(() => {
                this.questionSet = questionSet;
            });
        });
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public onReasoningChange = (reasoning: string) => {
        this.reasoning = reasoning;
    };

    public onReject = () => {
        if (!this.reasoning) {
            runInAction(() => {
                this.showReasoningRequiredError = true;
            });

            return;
        }

        this.submit(`/appraisals/${this.appraisal?.id}/pre-check/reject`, {
            reasoning: this.reasoning,
        });
    };

    public onAccept = () => {
        this.submit(`/appraisals/${this.appraisal?.id}/pre-check/accept`, {
            reasoning: this.reasoning,
            ...this.getAcceptData(),
        });
    };

    private submit(url: string, data: {[key: string]: unknown}) {
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', url);

        for (const key in data) {
            const hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', String(data[key]));
            form.appendChild(hiddenField);
        }

        const csrfToken = (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content;

        const csrfField = document.createElement('input');
        csrfField.setAttribute('type', 'hidden');
        csrfField.setAttribute('name', '_token');
        csrfField.setAttribute('value', csrfToken);
        form.appendChild(csrfField);

        document.body.appendChild(form);
        form.submit();
    }

    private getAcceptData() {
        const signData = document.getElementById('sign-data');
        const localKnowledgeData = document.getElementById('local-knowledge-data');

        const data: {[key: string]: unknown} = {};

        if (signData) {
            const formData = new FormData(signData as HTMLFormElement);
            formData.forEach((value, key) => {
                data[key] = value;
            });
        }

        if (localKnowledgeData) {
            const formData = new FormData(localKnowledgeData as HTMLFormElement);
            formData.forEach((value, key) => {
                data[key] = value;
            });
        }

        return data;
    }
}
